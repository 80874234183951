<template>
  <div class="collection-form white pa-5 py-3">
    <village-view
      @submitForm="submitHandler"
      @cancel="$emit('close')"
      :loading="loading"
      :item="item"
      :isEdit="isEdit"
    />
  </div>
</template>

<script>
import VillageView from "./VillageView.vue";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VillageView,
  },
  data: () => ({
    obj: {},
    imgFile: "",
    loading: false,
  }),
  methods: {
    async submitHandler(val) {
      this.loading = true;
      this.obj = {
        name: val.name,
        body: val.body,
        head: val.head,
        sections: val.sections,
        featured: val.featured,
        visible: val.visible,
        coverImage: val.coverImage,
        district: val.district,
        direction: val.direction,
        long: val.direction.long,
        lat: val.direction.lat,
        country: val.country
      };
      this.imgFile = val.imgFile;
      var itemID;
      if (this.isEdit) {
        itemID = this.item._id;
      } else {
        let createResponse = await this.$axios.post(`villages/add/new`, {
          long: this.obj.direction.long,
          lat: this.obj.direction.lat,
        });
        if (createResponse && createResponse.data && createResponse.data.data) {
          itemID = createResponse.data.data._id;
        }
      }
      if (!itemID) { return }
      this.obj.media = val.media || [];
      // upload media
      val.files.forEach(async (img) => {
        if (!img._id) {
          let formDataMedia = new FormData();
          formDataMedia.append("villageId", itemID);
          formDataMedia.append("media", img);
          // request to upload media
          let responseMedia = await this.$axios.post(
            `media/village/media`,
            formDataMedia,
            {
              "content-type": "multipart/form-data",
            }
          );
          if (responseMedia && responseMedia.data && responseMedia.data.data) {
            this.obj.media = [...responseMedia.data.data.media];
          }
        } else {
          this.obj.media = [...img._id];
        }
      });
      if (this.imgFile) {
        // upload image
        let formData = new FormData();
        formData.append("villageId", itemID);
        formData.append("coverImage", this.imgFile);
        // request to upload image
        let responseImage = await this.$axios.post(`media/village/cover`, formData, {
          "content-type": "multipart/form-data",
        });
        if (
          responseImage &&
          responseImage.data &&
          responseImage.data.data &&
          responseImage.data.data.media &&
          responseImage.data.data.media.length > 0
        ) {
          this.obj.coverImage =
            responseImage.data.data.media[
              responseImage.data.data.media.length - 1
            ];
        }
      }

      let resEdit = await this.$axios.put(`villages/${itemID}`, this.obj);
      if (resEdit.data) {
        this.$store.dispatch("showSnack", {
          text: this.isEdit ? "Edited successfully" : "Added successfully",
          color: `success`,
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: resEdit.message,
          color: `success`,
        });
      }
      this.$emit("close");
      this.$emit("reloadData", itemID);
      this.loading = false;
    },
    closeHandler() {
      this.$emit("close");
      this.loading = false;
    },
  },
  created() {
    if (this.isEdit) {
      this.obj = { ...this.item };
    }
  },
};
</script>

<style></style>
