<template>
  <div class="villages-body py-5" v-if="itemID">
    <div class="pb-2 d-flex" v-if="!viewOnly">
      <v-icon
        size="40"
        class="cursor-pointer ml-auto"
        @click="collapse"
        color="black"
        >mdi-progress-close</v-icon
      >
    </div>
    <div class="villages-body-content">
      <div class="main-item pt-3" v-if="!viewOnly">
        <div class="main-img">
          <img
            v-if="itemID.coverImage"
            width="100%"
            height="100%"
            class="object-cover"
            :src="itemID.coverImage.url"
            @click="photosDialogHandler(itemID.coverImage.url)"
            alt=""
          />
        </div>
        <div class="b-bottom pt-4">
          <!-- text details -->
          <div class="main-title d-flex align-center justify-space-between">
            <div>
              <span
                class="main-title d-block my-2 black--text transition-3s font-20 font-500"
                >{{ itemID.name[$root.language] }}</span
              >
            </div>
            <div class="d-block" v-if="!viewOnly">
              <div
                v-if="actions.includes('delete')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="deleteConfirm"
              >
                <v-icon color="error">mdi-delete</v-icon>
              </div>
              <div
                v-if="actions.includes('editCompanion')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="editCompanionValues"
              >
                <v-icon color="black-grey">mdi-percent-outline</v-icon>
              </div>
              <div
                v-if="actions.includes('edit')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="editHandler"
              >
                <v-icon color="black-grey">mdi-pencil</v-icon>
              </div>
              <div
                v-if="actions.includes('restore')"
                class="icon-cover mr-1 cursor-pointer base-btn"
                @click="$emit('restoreHandler', itemID)"
              >
                <v-icon color="green">mdi-file-undo</v-icon>
              </div>
            </div>
          </div>
          <span style="width: 100%; float: left">
            <p
              style="float: left"
              class="font-15 mt-3 mr-2"
              :class="{
                'green--text': itemID.featured,
                'is-not-selected': !itemID.featured
              }"
            >
              Featured
            </p>
            <p
              style="float: left"
              class="font-15 mt-3 mr-2"
              :class="{
                'green--text': itemID.visible,
                'is-not-selected': !itemID.visible
              }"
            >
              Visible
            </p>
            <p
              style="float: left"
              class="font-15 mt-3 mr-2"
              :class="{
                'green--text': itemID.sections && itemID.sections.restaurants,
                'is-not-selected': !(
                  itemID.sections && itemID.sections.restaurants
                )
              }"
            >
              Restaurants
            </p>
            <p
              style="float: left"
              class="font-15 mt-3 mr-2"
              :class="{
                'green--text': itemID.sections && itemID.sections.guesthouses,
                'is-not-selected': !(
                  itemID.sections && itemID.sections.guesthouses
                )
              }"
            >
              Guesthouses
            </p>
            <p
              style="float: left"
              class="font-15 mt-3 mr-2"
              :class="{
                'green--text': itemID.sections && itemID.sections.activities,
                'is-not-selected': !(
                  itemID.sections && itemID.sections.activities
                )
              }"
            >
              Activities
            </p>
          </span>
          <img
            style="float: right"
            v-if="itemID && itemID.district && itemID.district.icon"
            :src="itemID.district.icon.url"
            alt=""
          />
          <p class="black--text font-15 mt-3">
            {{ itemID.head[$root.language] }}
          </p>
          <p class="gray--text font-14">
            {{ itemID.body[$root.language] }}
          </p>
          <span>
            <p
              v-if="itemID && itemID.district"
              class="black--text font-15 mt-3"
            >
              District: {{ itemID.district.name[$root.language] }}
            </p>
          </span>
          <span>
            <p
              v-if="itemID && itemID.district"
              class="black--text font-15 mt-3"
            >
              City: {{ itemID.direction.name }}
            </p>
            <GmapMap
              v-if="itemID && itemID.direction"
              :options="{ scrollwheel: false }"
              :clickable="false"
              :draggable="false"
              ref="maps"
              :center="{
                lng: Number(itemID.direction.long),
                lat: Number(itemID.direction.lat)
              }"
              :zoom="15"
              map-type-id="roadmap"
              style="width: 100%; height: 200px"
            >
              <GmapMarker
                v-if="itemID.direction.long && itemID.direction.lat"
                :position="{
                  lng: Number(itemID.direction.long),
                  lat: Number(itemID.direction.lat)
                }"
              />
            </GmapMap>
          </span>
        </div>
      </div>
      <div class="photos-section b-bottom pb-4 mt-4">
        <v-row>
          <v-col cols="6" v-if="itemID.media && itemID.media.length > 0">
            <div class="photo mb-2 cursor-pointer">
              <img
                :src="itemID.media[0].url"
                alt=""
                @click="photosDialogHandler"
              />
            </div>
          </v-col>

          <!-- other images -->
          <v-col cols="6" v-if="itemID.media && itemID.media.length > 1">
            <div class="photo mb-2">
              <div
                v-if="itemID.media && itemID.media.length > 2"
                class="photo-count cursor-pointer"
                @click="photosDialogHandler"
              >
                <span class="white--text font-26"
                  >{{ itemID.media && itemID.media.length - 2 }}+</span
                >
              </div>
              <img :src="itemID.media[1].url" alt="" />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :images="images"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="750px">
      <village-dialog
        :key="editDialog"
        :isEdit="true"
        :item="itemID"
        @close="closeEditDialogHandler"
        @reloadData="$emit('reloadData')"
      ></village-dialog>
    </v-dialog>

    <v-dialog v-model="editCompanionValuesDialog" max-width="750px">
      <edit-village-companion
        :key="editCompanionValuesDialog"
        :item="itemID"
        @close="closeEditCompanionValuesDialog"
        @reloadData="$emit('reloadData')"
      ></edit-village-companion>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
  <div v-else style="height: 100vh" class="pt-5">
    <v-skeleton-loader type="card"></v-skeleton-loader>
    <v-col cols="12" md="12" v-for="(item, i) in [1, 2, 3, 4, 5, 6]" :key="i">
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </v-col>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";
import VillageDialog from "@/components/dialogs/village/Index.vue";
import EditVillageCompanion from "@/components/dialogs/village/Companion.vue";
export default {
  props: {
    itemID: {
      type: [Object],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["editCompanion", "delete", "edit"]
    }
  },
  components: {
    DialogPhotos,
    VillageDialog,
    EditVillageCompanion
  },
  data: () => ({
    photosDialog: false,
    deleteDialog: false,
    editCompanionValuesDialog: false,
    images: [],
    editDialog: false,

    villagesDelete: { Villages: "delete" },
    villagesEdit: { Villages: "edit" },
    deleteEnable: false,
    editEnable: false
  }),
  methods: {
    photosDialogHandler() {
      this.images = this.itemID.media;
      this.photosDialog = true;
    },
    editCompanionValues() {
      this.editCompanionValuesDialog = true;
    },
    editHandler() {
      this.editDialog = true;
    },
    deleteConfirm() {
      this.deleteDialog = true;
    },
    closeEditDialogHandler() {
      this.editDialog = false;
    },
    closeEditCompanionValuesDialog() {
      this.editCompanionValuesDialog = false;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/villages/${this.itemID._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    collapse() {
      this.$emit("collapse");
    }
  },
  async created() {
    this.deleteEnable = await this.$store.dispatch(
      "checkPermission",
      this.villagesDelete
    );
    this.editEnable = await this.$store.dispatch(
      "checkPermission",
      this.villagesEdit
    );
  }
};
</script>

<style lang="scss" scoped>
.villages-body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .villages-body-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .main-item {
      .main-img {
        border: 1px solid #eaedf3;
        height: 300px;
        border-radius: 5px;
        overflow: hidden;
        img {
          overflow: hidden;
        }
      }
    }
  }
}
</style>
