<template>
  <div class="collection-form-step">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div class="collection-form-step-head py-3">
      <span class="black--text font-16 font-500">Add Village Details</span>
    </div>
    <div class="collection-form-step-content py-5 px-5">
      <v-form v-model="valid" @submit.prevent="submitHandler" ref="formVillage">
        <v-row>
          <!-- upload image -->
          <v-col cols="12" class="py-1 pb-2">
            <div class="upload-image">
              <img
                v-if="obj.coverImage || newImage"
                :src="newImage ? newImage : obj.coverImage.url"
                alt=""
              />

              <div class="upload-btn cursor-pointer">
                <v-icon dark color="grey">mdi-upload</v-icon>
                <span class="font-15 grey--text">Upload Image</span>
                <v-file-input
                  accept="image/*"
                  label="File input"
                  @change="newImageHandler"
                  class="file-upload"
                  hide-details="true"
                ></v-file-input>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="py-1 pb-2">
            <div :key="filesUploaded">
              <div
                v-for="(file, i) in [...(obj.media || []), ...(files || [])]"
                :key="i"
              >
                <div
                  class="upload-item d-flex align-center justify-space-between px-6 py-3 mb-2 skyblue"
                >
                  <div>
                    <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
                    <span class="font-14 black--text d-inline-block ml-2">
                      {{ file.fileName || file.name }}
                    </span>
                  </div>
                  <span class="black--text font-14">{{ file.size }}</span>
                  <v-icon
                    class="upload-item-delete"
                    size="16"
                    @click="deleteFile(i)"
                    >mdi-close</v-icon
                  >
                </div>
              </div>
            </div>

            <!-- upload new -->
            <div class="upload-item px-6 py-3">
              <div>
                <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
                <span class="font-14 black--text d-inline-block ml-2"
                  >Upload Image</span
                >
              </div>
              <v-file-input
                prepend-icon=""
                @change="handleUpload"
                height="50"
                hide-details="true"
                accept="image/*"
                multiple
              ></v-file-input>
            </div>
          </v-col>
          <!-- name -->
          <v-col cols="12" md="6" class="py-1">
            <label class="grey--text font-12 font-500">Village Name</label>
            <base-input
              placeholder="Add a short, clear name"
              v-model="obj.name[language]"
            >
            </base-input>
          </v-col>
          <!-- district -->
          <v-col cols="12" md="6" class="py-1">
            <label class="grey--text font-12 font-500">District</label>
            <base-select
              :items="districts"
              itemText="name"
              v-model="obj.district"
              itemValue="_id"
              placeholder="Select district"
            />
          </v-col>
          <!-- Description -->

          <!-- head -->
          <v-col cols="12" md="12" class="pt-1 pb-0">
            <label class="grey--text font-12 font-500">Head</label>
            <base-textarea
              placeholder="What is this event about? Tell us more juicy details."
              v-model="obj.head[language]"
            ></base-textarea>
          </v-col>
          <!-- description -->
          <v-col cols="12" md="12" class="pt-1 pb-0">
            <label class="grey--text font-12 font-500">Description</label>
            <base-textarea
              v-model="obj.body[language]"
              placeholder="What is this event about? Tell us more juicy details."
            ></base-textarea>
          </v-col>

          <!-- LOCATION -->
          <v-col cols="12" md="6" class="py-1">
            <label class="grey--text font-12 font-500"> City Name </label>
            <GmapAutocomplete
              class="place-input"
              ref="gMapAutocomplete"
              :select-first-on-enter="true"
              placeholder="City name"
              @place_changed="setPlace"
            />
          </v-col>
          <v-col cols="12" md="12" class="py-1">
            <GmapMap
              :clickable="true"
              ref="maps"
              :center="center"
              :zoom="zoom"
              map-type-id="roadmap"
              style="width: 100%; height: 100px"
              @click="addMarker"
            >
              <GmapMarker
                v-if="this.marker"
                :position="{ lat: this.marker.lat, lng: this.marker.lng }"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row>
          <!-- featured -->
          <v-col cols="12" md="6" class="pt-0">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="obj.featured"
                class="mr-3"
                dense
                hide-details="true"
                label="Featured"
              ></v-checkbox>
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="obj.visible"
                  class="mr-3"
                  dense
                  hide-details="true"
                  label="Visible"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
        <span class="d-block black--text font-16 d-block mt-2"
          >Add preview sections</span
        >
        <v-row class="mt-1">
          <v-col cols="4">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="obj.sections.restaurants"
                class="mr-3"
                dense
                hide-details="true"
                label="Restaurants"
              ></v-checkbox>
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="obj.sections.guesthouses"
                  class="mr-3"
                  dense
                  label="Guesthouses"
                  hide-details="true"
                ></v-checkbox>
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="obj.sections.activities"
                    class="mr-3"
                    dense
                    hide-details="true"
                    label="Activities"
                  ></v-checkbox>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <buttons-experience
          @cancel="$emit('cancel')"
          @next="submitHandler"
          text="Submit"
          :isValid="valid"
          :key="valid"
        ></buttons-experience>
      </v-form>
    </div>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    ButtonsExperience
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    marker: undefined,
    center: { lng: 35.8984486, lat: 33.8786494 },
    zoom: 9.5,
    valid: false,
    obj: {
      name: {},
      head: {},
      body: {},
      sections: { restaurants: true, guesthouses: true, activities: true },
      coverImage: {},
      direction: {},
      featured: true,
      visible: true
    },
    districts: [],
    newImage: "",
    location: "",
    files: [],
    filesUploaded: false,
    language: "en"
  }),
  methods: {
    handleUpload(files) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        this.files.push(file);
      });
      this.filesUploaded = !this.filesUploaded;
    },
    deleteFile(i) {
      this.files = [...this.files.filter((file, idx) => idx != i)];
      if (this.obj.media) {
        this.obj.media = [...this.obj.media.filter((file, idx) => idx != i)];
      }
      this.filesUploaded = !this.filesUploaded;
    },
    newImageHandler(file) {
      this.newImage = URL.createObjectURL(file);
      this.obj.imgFile = file;
    },
    submitHandler() {
      this.$refs.formVillage.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }

      if (
        !this.obj.direction.long ||
        !this.obj.direction.lat ||
        !this.obj.direction.name
      ) {
        this.$store.dispatch("showSnack", {
          text: "City is Missing",
          color: "error"
        });
        return;
      }

      if ((this.isEdit && this.obj.coverImage) || this.obj.imgFile) {
        this.obj.files = this.files;
        this.$emit("submitForm", this.obj);
      } else {
        this.$store.dispatch("showSnack", {
          text: "Cover image is missing!",
          color: "error"
        });
      }
    },
    async fetchDistricts() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.districts = data.districts;
      }
    },
    setPlace(event) {
      if (event.geometry && event.geometry.location) {
        this.marker = {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
        };
        this.center = event.geometry.location;
        this.zoom = 15;
        this.obj.direction.long = this.marker.lng;
        this.obj.direction.lat = this.marker.lat;
        this.obj.direction.name = this.$refs.gMapAutocomplete.$el.value;
      }
    },
    addMarker(event) {
      this.marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      this.obj.direction.long = this.marker.lng;
      this.obj.direction.lat = this.marker.lat;
      this.obj.direction.name = this.$refs.gMapAutocomplete.$el.value;
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.fetchDistricts();
    if (this.isEdit && this.item.name) {
      this.obj = {
        visible: this.item.visible,
        featured: this.item.featured,
        district: this.item.district._id,
        coverImage: this.item.coverImage,
        body: this.item.body,
        head: this.item.head,
        name: this.item.name,
        country: this.$root.country.short,
        sections: this.item.sections ? this.item.sections : {},
        direction: this.item.direction,
        media: this.item.media
      };
      this.marker = {
        lng: Number(this.obj.direction.long),
        lat: Number(this.obj.direction.lat)
      };
      this.center = this.marker;
      this.zoom = 15;
      this.$refs.gMapAutocomplete.$el.value = this.obj.direction.name;
    }
  }
};
</script>

<style lang="scss">
.place-input {
  height: 36px;
  line-height: 35px;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
.collection-form-step {
  .collection-form-step-head {
    border-bottom: 1px solid rgba($grayicon, 0.7);
  }
  .collection-form-step-btns {
    border-top: 1px solid rgba($grayicon, 0.7);
  }
  .upload-image {
    width: 100%;
    height: 150px;
    border: 1px solid #e2e5ed;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .upload-btn {
      position: absolute;
      bottom: 20px;
      left: 20px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.5);
      padding: 5px 10px;

      .file-upload {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
        margin: 0px !important;
        padding: 0px !important;

        label {
          display: none !important;
        }
      }
    }
  }
}
</style>
