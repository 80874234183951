<template>
  <div class="collection-form white pa-5 py-3">
    <div class="max-width d-flex">
      <div class="mt-3 d-flex">
        <span
          class="main-title d-block my-2 black--text transition-3s font-20 font-500"
          >{{ item.name }} - Companion locations weights
        </span>
      </div>
    </div>
    <div class="divider-dark mb-2"></div>
    <!-- inputs -->
    <div class="village-input px-4 py-6" v-if="loading">
      <v-row class="px-8 mt-2" v-if="loading">
        <v-col
          cols="12"
          md="12"
          v-for="(item, i) in [1, 2, 3, 4, 5, 6]"
          :key="i"
        >
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="village-input px-4 py-6" v-if="!loading">
      <v-form v-model="valid" @submit.prevent="submitHandler" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-row v-for="(input, i) in locations" :key="i">
              <v-col cols="3" v-if="input.location">
                <span class="word-wrap">
                  {{ input.location.title }}
                </span>
              </v-col>
              <v-col cols="4">
                <base-input
                  type="number"
                  v-model="input['score']"
                  :rules="[(v) => `${v}`.length > 0 && v >= 0 && v <= 10]"
                ></base-input>
              </v-col>
              <v-col cols="2"> / 10 </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <buttons
      @cancel="close"
      @next="submitHandler"
      text="Save"
      :isValid="valid"
      :key="valid"
    ></buttons>
  </div>
</template>

<script>
import Buttons from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    Buttons
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    editedVillage: undefined,
    locations: [],
    valid: false,
    loading: true
  }),
  methods: {
    async submitHandler() {
      if (this.editedVillage && this.editedVillage._id) {
        let res = await this.$axios.put(
          `villages/${this.editedVillage._id}`,
          this.editedVillage
        );
        if (res.data) {
          this.$store.dispatch("showSnack", {
            text: "Edited succesfully",
            color: `success`
          });
        } else {
          this.$store.dispatch("showSnack", {
            text: res.message,
            color: `error`
          });
        }
      }
      this.$emit("close");
      this.$emit("reloadData");
      this.loading = false;
    },
    close() {
      this.loading = false;
      this.$emit("close");
    },
    async fetchLocations() {
      this.loading = true;
      if (this.item && this.item._id) {
        let locationsSchemaData = await this.$axios.get(`/location`);
        let { data } = await this.$axios.get(`/villages/${this.item._id}`);
        if (data) {
          this.editedVillage = data[0];
          let locationScores = this.item.locationScores;
          this.locations = locationsSchemaData.data.locations.map(
            (location) => {
              let objectLocation = locationScores.find((l) => {
                return l.location ? l.location._id == location._id : false;
              });
              return {
                score: objectLocation ? objectLocation.score : 0,
                _id: objectLocation?._id,
                location: {
                  title: location.title,
                  _id: location._id
                }
              };
            }
          );
          this.editedVillage.locationScores = this.locations;
        }
        this.loading = false;
      }
    }
  },
  created() {
    this.fetchLocations();
  }
};
</script>

<style></style>
