<template>
  <div class="photos-dialog">
    <div class="close-btn">
      <v-icon @click="$emit('close')" dark>mdi-close</v-icon>
    </div>
    <div class="slider text-center">
      <slick-slider v-bind="settings">
        <div class="slide-item" v-for="(item,i) in images" :key="i">
          <img width="500" class="object-cover mx-auto" :src="type == 'experience' ? item.mediaId.url : item.url" />
        </div>
      </slick-slider>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    images:{
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    settings: {
      dots: false,
      fade: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }),
};
</script>

<style lang="scss">
.photos-dialog {
  background: rgba(0, 0, 0, 0.7);
  position: relative;
  height: 100%;
  .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 99999;
  }
  .slider{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      width: 100%
  }

  .slick-next{
      right: 20px;
      &::before{
          opacity: 1;
      }
  }
  .slick-prev{
      left: 20px;
      &::before{
          opacity: 1;
      }
  }

  .slick-track{
    display: flex;
    align-items: center;
  }
}
</style>
